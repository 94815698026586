import React, { Component } from 'react';
import { connect } from 'react-redux';
import { details } from '../../actions';
import { Properties } from '../../config';
import { isEmpty } from 'lodash';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import macOsCli from '../../assets/cli/mac/artifact-ctl.zip';
import linuxCli from '../../assets/cli/linux/artifact-ctl.zip';
import windowsCli from '../../assets/cli/windows/artifact-ctl.zip';

class ArtifactLinks extends Component {
  state = {
    osType: '',
  };
  componentDidMount() {
    if (this.props.artifact.jsonFileContent.parents.length === 0) {
      this.props
        .fetchJSONFileContent(
          this.props.artifact,
          this.props.currentUser,
          this.props.userProfile
        )
        .then((json) => console.debug('json', json));
    }
  }
  getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    this.setState({
      osType: os,
    });
  };

  copyArtifactCurl = () => {
    const el = this.artifactcurl;
    el.select();
    document.execCommand('copy');
  };

  navTabChange = async (event, value) => {
    if (value === 'app') {
      this.getOS();
    }
    if (value === 'http') {
      await this.props.fetchJSONFileContent(
        this.props.artifact,
        this.props.currentUser,
        this.props.userProfile
      );
    }
    await this.props.setActiveArtifactSubTab(value);
  };

  render() {
    const { artifact } = this.props;
    if (
      isEmpty(artifact.jsonInfo.artifactJsonFileName) ||
      isEmpty(artifact.jsonInfo.artifactJarFileName)
    ) {
      return (
        <div>
          <ol></ol>
          <p>
            For this file "{artifact.name} - {artifact.version}" there is no
            JSON or JAR file.
          </p>
        </div>
      );
    }
    return (
      <div>
        <Paper className="paper-nav-tabs">
          <Tabs
            value={artifact.activeSubTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => {
              this.navTabChange(event, value);
            }}
          >
            <Tab label="CDAP CLI" value="cli" />
            <Tab label="HTTP RESTful API" value="http" />
            <Tab label="DEPLOY PLUGIN APP" value="app" />
          </Tabs>
        </Paper>
        <div>
          {artifact.activeSubTab === 'http' && (
            <div style={{ marginTop: '20px' }}>
              <p style={{ fontWeight: 'bold' }}>
                Add Artifact using CURL
                <i
                  className="copyButton fa fa-copy fa-sm pull-right btn btn-primary btn-sm"
                  style={{ cursor: 'pointer', fontWeight: '400' }}
                  onClick={() => this.copyArtifactCurl()}
                >
                  Copy
                </i>
              </p>
              <textarea
                name="artifactcurl"
                readOnly={true}
                ref={(textarea) => (this.artifactcurl = textarea)}
                className="text-area-curl"
                value={`curl -s ${Properties.API_HOST}packages/${
                  artifact.name
                }/${artifact.version}/${
                  artifact.jsonInfo.artifactJarFileName
                } | curl -w"\\n" -X POST "localhost:11015/v3/namespaces/default/artifacts/${
                  artifact.jsonInfo.artifactName
                }" -H "Artifact-Version: ${
                  artifact.version
                }" -H "Artifact-Extends:${artifact.jsonFileContent.parents.join(
                  '/'
                )}" --data-binary @-
curl -w"\\n" -X PUT "localhost:11015/v3/namespaces/default/artifacts/${
                  artifact.jsonInfo.artifactName
                }/versions/${
                  artifact.version
                }/properties" -H "Content-Type: application/json" -d '${JSON.stringify(
                  artifact.jsonFileContent.properties
                )}'`}
              />
            </div>
          )}
          {artifact.activeSubTab === 'cli' && (
            <div style={{ marginTop: '20px' }}>
              <p>
                load artifact /path/to/{artifact.jsonInfo.artifactName}-
                {artifact.version}.jar config-file /path/to/
                {artifact.jsonInfo.artifactName}-{artifact.version}.json
              </p>
            </div>
          )}
          {artifact.activeSubTab === 'app' && (
            <div style={{ marginTop: '20px' }}>
              <label htmlFor="opTypes" style={{ marginRight: '10px' }}>
                Choose OS:
              </label>
              <select
                className="select-namespace"
                style={{ margin: '0px', width: '7.5rem' }}
                name="opTypes"
                value={this.state.osType}
                onChange={(event) => {
                  this.setState({
                    osType: event.target.value,
                  });
                }}
              >
                <option value="Linux">Linux</option>
                <option value="Mac OS">Mac OS</option>
                <option value="Windows">Windows</option>
              </select>

              {this.state.osType === 'Linux' && (
                <div className="row" style={{ margin: '15px 0px' }}>
                  <i className="fa fa-linux" style={{ fontSize: '45px' }} />
                  <a
                    download={'artifact-ctl'}
                    href={linuxCli}
                    style={{ margin: '13px 20px' }}
                  >
                    Download app
                  </a>
                </div>
              )}
              {this.state.osType === 'Mac OS' && (
                <div className="row" style={{ margin: '15px 0px' }}>
                  <i className="fa fa-apple" style={{ fontSize: '45px' }} />
                  <a
                    download={'artifact-ctl'}
                    href={macOsCli}
                    style={{ margin: '13px 20px' }}
                  >
                    Download app
                  </a>
                </div>
              )}
              {this.state.osType === 'Windows' && (
                <div className="row" style={{ margin: '15px 0px' }}>
                  <i className="fa fa-windows" style={{ fontSize: '45px' }} />
                  <a
                    download={'artifact-ctl'}
                    href={windowsCli}
                    style={{ margin: '13px 20px' }}
                  >
                    Download app
                  </a>
                </div>
              )}
              <h3>Usage:</h3>
              <ol>
                <li>
                  <p>Add artifact:</p>
                  <p>
                    <code>
                      {this.state.osType === 'Windows' ? '.\\' : './'}
                      artifact-ctl load --json &lt;json-file-path&gt; --jar
                      &lt;jar-file-path&gt; --host &lt;CDAP host&gt; --namespace
                      &lt;namespace in CDAP env&gt;
                    </code>
                  </p>
                </li>
                <li>
                  <p>Delete artifact:</p>
                  <p>
                    <code>
                      {this.state.osType === 'Windows' ? '.\\' : './'}
                      artifact-ctl delete --host &lt;CDAP host&gt; --namespace
                      &lt;namespace in CDAP env&gt; --name &lt;plugin name&gt;
                      --version &lt;plugin version&gt;
                    </code>
                  </p>
                </li>
              </ol>
              <h3>Flags:</h3>
              <pre>
                <code>
                  --name TEXT [n] Artifact name [required]
                  <br />
                  --version [v] Artifact version [default: extracts it
                  automatically from file name (--name)]
                  <br />
                  --jar [r] Path to JAR file [required] [default: '']
                  <br />
                  --json [j] Path to JSON file [required] [default: '']
                  <br />
                  --host [o] CDAP Instance URL [required] [default:
                  http://localhost:11015]
                  <br />
                  --namespace [s] Namespace to deploy artifact [required]
                  [default: 'default'] <br />
                  --auth_token [a] Authentication token [default: '']
                  <br />
                </code>
              </pre>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  currentUser: state.user.currentUser,
  userProfile: state.user.userProfile,
});

const mapActionsToProps = {
  setActiveArtifactSubTab: details.setArtifactSubTab,
  setArtifactJsonFileContent: details.setArtifactJsonFileContent,
  fetchJSONFileContent: details.fetchJSONFileContent,
};

export default connect(mapStateToProps, mapActionsToProps)(ArtifactLinks);
