import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paymentMethods, shoppingCart } from '../../actions';
import { Card } from './Card';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalInformation: undefined,
      isConfirmModalOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchProducts();
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      isConfirmModalOpen: !prevState.isConfirmModalOpen
    }));
  };

  handleCancelSubscription = () => {
    this.props.cancelSubscription();
    this.props.history.push('/receipt/cancel-subscription');
    this.toggleConfirmModal();
  };

  // TODO fix ui and functionality
  render() {
    if (this.props.isLoadingProducts) {
      return <div style={{ paddingTop: '2.5rem' }}>Loading...</div>;
    } else {
      return (
        <>
          <div className="pricing-plans" style={{ paddingTop: '2.0rem' }}>
            <b style={{ fontSize: '20px' }}>Choose a Plan</b>
            <p>Select a plan that's right for you.</p>
            <div className="price-cards" style={{ paddingTop: '' }}>
              {this.props.products.map((product) => (
                <Card
                  priceId={product.price?.id}
                  name={product.name}
                  price={product.price?.amount}
                  features={product.meta.features}
                  currentPlan={this.props.currentPlan}
                  setPrice={this.props.setPrice}
                  currentPlanPrice={
                    this.props.products.find(
                      (product) => product.name === this.props.currentPlan
                    )?.price?.amount ?? 0
                  }
                  cancelSubscription={this.toggleConfirmModal}
                  image={product.image}
                />
              ))}
            </div>
          </div>
                    {/* Confirmation Modal for Subscription Cancellation */}
                    <Modal 
            isOpen={this.state.isConfirmModalOpen} 
            toggle={this.toggleConfirmModal}
          >
            <ModalHeader toggle={this.toggleConfirmModal}>
              Confirm Subscription Cancellation
            </ModalHeader>
            <ModalBody>
              Are you sure you want to cancel your current subscription? 
              This action cannot be undone and you will lose access to your current plan's features.
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleConfirmModal}>
                No, Keep Subscription
              </Button>
              <Button color="danger" onClick={this.handleCancelSubscription}>
                Yes, Cancel Subscription
              </Button>
            </ModalFooter>
          </Modal>
          <section className="pt-5">
            <div className="pt-4 container">
              <h1 className="text-center mb-2 py-5">Pricing Details</h1>
              <div className="pricing-table-wraper">
                <table className="pricing-table">
                  <thead className="thead">
                    <tr className="table-row">
                      <th>Features</th>
                      <th>Developer</th>
                      <th>Team</th>
                      <th>Business</th>
                      <th>Enterprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Unlimited open-source plugins</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Centralized and secure artifact storage</td>
                      <td>X</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Private plugins</td>
                      <td>X</td>
                      <td>10</td>
                      <td>50</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Share plugins with closed source and licenses</td>
                      <td>X</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Sandbox instances</td>
                      <td>X</td>
                      <td>1 (dynamic IP)</td>
                      <td>3 IPs</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Pipeline management and batch operations</td>
                      <td>X</td>
                      <td>X</td>
                      <td>X</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Requires a GitHub repository</td>
                      <td>✓</td>
                      <td>X</td>
                      <td>X</td>
                      <td>X</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  products: state.paymentMethods.products,
  isLoadingProducts: state.paymentMethods.isLoadingProducts,
  // ...ownProps,
});

const mapActionsToProps = {
  setPrice: shoppingCart.setPrice,
  cancelSubscription: shoppingCart.cancelSubscription,
  fetchProducts: paymentMethods.fetchProducts,
};
export default connect(mapStateToProps, mapActionsToProps)(Plans);
