import React, { Component } from 'react';

class LegalAgreement extends Component {
  render() {
    return (
      <div className="container py-4">
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div
              className="card-body bg-white rounder-borders card-body-shadow"
              style={{ padding: '45px' }}
            >
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="">LEGAL AGREEMENT</h3>
                  <p className="uk-text-large uk-margin-small-top">
                    Last updated{' '}
                    {new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
                      new Date()
                    )}
                    {', '}
                    {new Date().getFullYear()}
                  </p>
                </div>
                <div className="col-lg-12">
                  <p>
                    AdaptiveScale, Inc. (“we” or “us” or “our”) respects the
                    privacy of our users (“user” or “you”). This Privacy Policy
                    explains how we collect, use, disclose, and safeguard your
                    information when you visit our website
                    http://www.adaptivescale.com, including any other media
                    form, media channel, mobile website, or mobile application
                    related or connected thereto (collectively, the “Site”).
                    Please read this Privacy Policy carefully. IF YOU DO NOT
                    AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT
                    ACCESS THE SITE.
                  </p>
                  <p>
                    We reserve the right to make changes to this Privacy Policy
                    at any time and for any reason. We will alert you about any
                    changes by updating the “Revised” date of this Privacy
                    Policy. Any changes or modifications will be effective
                    immediately upon posting the updated Privacy Policy on the
                    Site, and you waive the right to receive specific notice of
                    each such change or modification. You are encouraged to
                    periodically review this Privacy Policy to stay informed of
                    updates. You will be deemed to have been made aware of, will
                    be subject to, and will be deemed to have accepted the
                    changes in any revised Privacy Policy by your continued use
                    of the Site after the date such revised Privacy Policy is
                    posted.
                  </p>
                </div>
                <div className="col-lg-12">
                  <h3>COLLECTION OF YOUR INFORMATION</h3>
                  <p>
                    We may collect information about you in a variety of ways.
                    The information we may collect on the may include personally
                    identifiable information, such as your name, address, email,
                    and telephone number, that you voluntarily give to us when
                    you choose to participate in various activities related to
                    the Site. You are under no obligation to provide us with
                    personal information of any kind, however your refusal to do
                    so may prevent you from using certain features of the Site.
                  </p>
                </div>
                <div className="col-lg-12">
                  <h3>USE OF YOUR INFORMATION</h3>
                  <p>
                    {' '}
                    Having accurate information about you permits us to provide
                    you with a smooth, efficient, and customized experience.
                    Specifically, we may use information collected about you via
                    the Site to:
                  </p>
                  <ul>
                    <li>
                      Compile anonymous statistical data and analysis for use
                      internally or with third parties.
                    </li>
                    <li>Perform other business activities as needed.</li>
                    <li>Respond to product and customer service requests.</li>
                    <li>Send you a newsletter.</li>
                  </ul>
                </div>
                <div className="col-lg-12">
                  <h3>DISCLOSURE OF YOUR INFORMATION </h3>
                </div>
                <div className="col-lg-12">
                  <p>
                    We may share information we have collected about you in
                    certain situations. Your information may be disclosed as
                    follows:
                    <br />
                    <b>By Law or to Protect Rights </b>
                    If we believe the release of information about you is
                    necessary to respond to legal process, to investigate or
                    remedy potential violations of our policies, or to protect
                    the rights, property, and safety of others, we may share
                    your information as permitted or required by any applicable
                    law, rule, or regulation. This includes exchanging
                    information with other entities for fraud protection and
                    credit risk reduction
                    <br />
                    <b>Affiliates </b>
                    We may share your information with our affiliates, in which
                    case we will require those affiliates to honor this Privacy
                    Policy. Affiliates include our parent company and any
                    subsidiaries, joint venture partners or other companies that
                    we control or that are under common control with us.
                    <br />
                    <b>Business Partners </b>
                    We may share your information with our business partners to
                    offer you certain products, services or promotions.
                    <br />
                    <b>Other Third Parties </b>
                    We may share your information with advertisers and investors
                    for the purpose of conducting general business analysis. We
                    may also share your information with such third parties for
                    marketing purposes, as permitted by law.
                    <br />
                    <b>Sale or Bankruptcys </b>
                    If we reorganize or sell all or a portion of our assets,
                    undergo a merger, or are acquired by another entity, we may
                    transfer your information to the successor entity. If we go
                    out of business or enter bankruptcy, your information would
                    be an asset transferred or acquired by a third party. You
                    acknowledge that such transfers may occur and that the
                    transferee may decline honor commitments we made in this
                    Privacy Policy.
                    <br />
                    We are not responsible for the actions of third parties with
                    whom you share personal or sensitive data, and we have no
                    authority to manage or control third-party solicitations. If
                    you no longer wish to receive correspondence, emails or
                    other communications from third parties, you are responsible
                    for contacting the third party directly.
                  </p>
                </div>
                <div className="col-lg-12">
                  <h3>TRACKING TECHNOLOGIES</h3>
                  <p>
                    <b>Website Analytics</b>
                  </p>
                  <p>
                    We may also partner with selected third-party vendors, such
                    as Google Analytics, to allow tracking technologies and
                    remarketing services on the Site through the use of first
                    party cookies and third-party cookies, to, among other
                    things, analyze and track users’ use of the Site, determine
                    the popularity of certain content and better understand
                    online activity. By accessing the Site, you consent to the
                    collection and use of your information by these third-party
                    vendors. You are encouraged to review their privacy policy
                    and contact them directly for responses to your questions.
                    We do not transfer personal information to these third-party
                    vendors. However, If you do not want any information to be
                    collected and used by tracking technologies, you can visit
                    the third-party vendor or
                    <label className="color-blue">
                      Network Advertising Initiative Opt-Out Tool or Digital
                      Advertising Alliance Opt-Out Tool.
                    </label>
                  </p>
                </div>
                <div className="col-lg-12">
                  <h3>THIRD-PARTY WEBSITES</h3>
                  <p>
                    The Site may contain links to third-party websites and
                    applications of interest, including advertisements and
                    external services, that are not affiliated with us. Once you
                    have used these links to leave the Site, any information you
                    provide to these third parties is not covered by this
                    Privacy Policy, and we cannot guarantee the safety and
                    privacy of your information. Before visiting and providing
                    any information to any third-party websites, you should
                    inform yourself of the privacy policies and practices (if
                    any) of the third party responsible for that website, and
                    should take those steps necessary to, in your discretion,
                    protect the privacy of your information. We are not
                    responsible for the content or privacy and security
                    practices and policies of any third parties, including other
                    sites, services or applications that may be linked to or
                    from the Site.
                  </p>
                </div>
                <div className="col-lg-12">
                  <h3>SECURITY OF YOUR INFORMATION</h3>
                  <p>
                    We use administrative, technical, and physical security
                    measures to help protect your personal information. While we
                    have taken reasonable steps to secure the personal
                    information you provide to us, please be aware that despite
                    our efforts, no security measures are perfect or
                    impenetrable, and no method of data transmission can be
                    guaranteed against any interception or other type of misuse.
                    Any information disclosed online is vulnerable to
                    interception and misuse by unauthorized parties. Therefore,
                    we cannot guarantee complete security if you provide
                    personal information.
                  </p>
                </div>
                <div className="col-lg-12">
                  <h3>CONTACT US</h3>
                  <p>
                    If you have questions or comments about this Privacy Policy,
                    please contact us at:{' '}
                  </p>
                  {/* <p> */}
                  <ul className="">
                    <li>AdaptiveScale, Inc.</li>
                    <li>447 Broadway, 2nd Floor</li>
                    <li>New York, NY 10013</li>
                    <li>(929) 244-0822</li>
                    <li>info@adaptivescale.com</li>
                  </ul>
                  {/* </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalAgreement;
