import React, { Component } from 'react';
import adaptiveScaleLight from '../../assets/images/adaptivescale-logo-light.png';
import { withRouter } from '../../hooks';

class Footer extends Component {
  render() {
    return (
      <footer
        style={{
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: '#212b36',
        }}
      >
        <div
          className="container"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                margin: '1em 15px',
                display: 'flex',
                justifyContent: 'center',
                gap: '1em',
              }}
            >
              <div>
                <p
                  onClick={() => {
                    this.props.history.push('/terms-conditions');
                  }}
                  style={{ color: '#f5f5f5', cursor: 'pointer' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="scale-on-hover"
                >
                  Terms of service
                </p>
              </div>
              <div> |</div>
              <div>
                <p
                  onClick={() => {
                    this.props.history.push('/privacy-policy');
                  }}
                  style={{ color: '#f5f5f5', cursor: 'pointer' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="scale-on-hover"
                >
                  Privacy policy
                </p>
              </div>
              <div> |</div>
              <div>
                <p
                  onClick={() => {
                    this.props.history.push('/legal-agreement');
                  }}
                  style={{ color: '#f5f5f5', cursor: 'pointer' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="scale-on-hover"
                >
                  Legal Agreement
                </p>
              </div>
            </div>
          </div>
          <img src={adaptiveScaleLight} alt={'logo'} height={30} width={210} />
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);
