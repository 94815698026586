import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paginateOss } from '../../actions/oss';
import { CardView, ListView, SkeletonComp } from '../';

const AllPlugins = ({
  keyword,
  cdapVersion,
  listView,
  goToDetails,
  activeTab,
  type,
  showBundles,
}) => {
  const dispatch = useDispatch();
  const oss = useSelector((state) => state.oss);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const scrollHandler = useRef();

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 5 &&
      ['all', 'openSource'].includes(oss.activeTab) &&
      oss.hasMoreData &&
      !oss.paginationIsLoading
    ) {
      dispatch(paginateOss(oss.page + 1, 20, keyword, cdapVersion, activeTab));
    }
  }, [
    dispatch,
    oss.activeTab,
    oss.hasMoreData,
    oss.paginationIsLoading,
    oss.page,
    keyword,
    cdapVersion,
  ]);

  useEffect(() => {
    scrollHandler.current = debounce(handleScroll, 200);
    window.addEventListener('scroll', scrollHandler.current);
    return () => window.removeEventListener('scroll', scrollHandler.current);
  }, [handleScroll]);

  useEffect(() => {
    if (isInitialized) {
      dispatch(paginateOss(1, 20, keyword));
    }
  }, [keyword]);

  useEffect(() => {
    if (isInitialized) {
      dispatch(paginateOss(1, 20, '', cdapVersion, activeTab, type));
    }
  }, [cdapVersion]);

  useEffect(() => {
    if (isInitialized) {
      dispatch(paginateOss(1, 20, '', cdapVersion, activeTab, type));
    }
  }, [type]);

  useEffect(() => {
    dispatch(paginateOss(1, 20, '', '', activeTab));
    setIsInitialized(true);
  }, [activeTab]);

  useEffect(() => {
    if (isInitialized) {
      dispatch(
        paginateOss(1, 20, '', cdapVersion, activeTab, type, showBundles)
      );
    }
  }, [showBundles]);

  const renderCardView = (itemsToRender) => {
    if (oss.isLoading) return <SkeletonComp />;
    return (
      <CardView
        data={itemsToRender.map((artifact) => ({ type: 'artifact', artifact }))}
        goToDetails={goToDetails}
      />
    );
  };

  const renderListView = (itemsToRender) => {
    if (oss.isLoading) return <SkeletonComp />;
    return (
      <ListView
        data={itemsToRender.map((artifact) => ({ type: 'artifact', artifact }))}
        filters={[]}
        goToDetails={goToDetails}
      />
    );
  };

  return (
    <div>{listView ? renderListView(oss.data) : renderCardView(oss.data)}</div>
  );
};

export default AllPlugins;
