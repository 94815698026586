import React from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from '@mui/material';
import { Search } from '../../components';
import Skeleton from '@mui/lab/Skeleton';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { client } from '../../config/api';

export const PluginsTable = ({
  plugins: pluginsRaw,
  users,
  isLoading,
  fetchData,
}) => {
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [keyword, setKeyword] = React.useState('');
  const [filteredData, setFilteredData] = React.useState([]);
  const [isLoadingActions, setIsLoadingAction] = React.useState(undefined);
  const [modalInformation, setModalInformation] = React.useState(undefined);

  const plugins = React.useMemo(
    () =>
      pluginsRaw.map((plugin) => ({
        ...plugin,
        user: users.find((user) => user.id === plugin.data.userId),
      })),
    [pluginsRaw, users]
  );

  React.useEffect(() => {
    setFilteredData([...plugins].slice(page * perPage, (page + 1) * perPage));
  }, [plugins, page, perPage]);

  React.useEffect(() => {
    if (keyword !== '') {
      setFilteredData(
        plugins.filter((plugin) => JSON.stringify(plugin).includes(keyword))
      );
      return;
    }
    setPage(0);
    setFilteredData([...plugins].slice(page * perPage, (page + 1) * perPage));
  }, [keyword]);

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          <div
            style={{
              width: '100%',
              display: 'flex',
              padding: '10px 0',
            }}
          >
            <div
              style={{
                width: 300,
                marginLeft: 'auto',
                marginRight: -24,
              }}
            >
              <Search handleSearch={(keyword) => setKeyword(keyword)} />
            </div>
          </div>
        </Toolbar>
        <Table
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
        >
          <TableHead style={{ fontWeight: 700, borderTop: '1px solid #ccc' }}>
            <TableRow style={{ height: 50 }}>
              <TableCell style={{ fontWeight: 700, color: '#164080' }}>
                ID
              </TableCell>
              <TableCell style={{ fontWeight: 700 }}>Name</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Version</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Author</TableCell>
              <TableCell style={{ fontWeight: 700 }}>User Email</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Visibility</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                </TableRow>
              ))}
            {!isLoading &&
              filteredData.map((plugin, index) => (
                <TableRow key={plugin.id} style={{ height: 60 }}>
                  <TableCell style={{ color: '#164080' }}>
                    {plugin.id}
                  </TableCell>
                  <TableCell>{plugin.data.name}</TableCell>
                  <TableCell>{plugin.data.version}</TableCell>
                  <TableCell>{plugin.data.author}</TableCell>
                  <TableCell>{plugin.user?.data?.email}</TableCell>
                  <TableCell>{plugin.data.visibility}</TableCell>
                  <TableCell>
                    {plugin.data.visibility === 'public' && (
                      <Button
                        color={'secondary'}
                        onClick={() => {
                          setModalInformation({
                            package_id: plugin.id,
                            visibility: 'private',
                            endpoint: '/packages_temp/visibility',
                            title: 'Change Visibility',
                            index: index,
                          });
                        }}
                      >
                        {isLoadingActions === index ? (
                          <CircularProgress />
                        ) : (
                          'Make private'
                        )}
                      </Button>
                    )}
                    {plugin.data.visibility === 'pending' && (
                      <Button
                        color={'secondary'}
                        onClick={() => {
                          setModalInformation({
                            package_id: plugin.id,
                            visibility: 'public',
                            endpoint: '/packages_temp/visibility',
                            title: 'Change Visibility',
                            index: index,
                          });
                        }}
                      >
                        {isLoadingActions === index ? (
                          <CircularProgress />
                        ) : (
                          'Approve plugin'
                        )}
                      </Button>
                    )}
                    {plugin.data.visibility === 'private' && (
                      <Button
                        color={'secondary'}
                        onClick={() => {
                          setModalInformation({
                            package_id: plugin.id,
                            visibility: 'public',
                            endpoint: '/packages_temp/visibility',
                            title: 'Change Visibility',
                            index: index,
                          });
                        }}
                      >
                        {isLoadingActions === index ? (
                          <CircularProgress />
                        ) : (
                          'Make public'
                        )}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={plugins.length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, value) => setPage(value)}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      {modalInformation && (
        <Modal isOpen={!!modalInformation}>
          <ModalHeader>{modalInformation.title}</ModalHeader>
          <ModalBody>
            Are you sure you want to {modalInformation.title}?
          </ModalBody>
          <ModalFooter>
            <Button
              color={'primary'}
              onClick={async () => {
                setIsLoadingAction(modalInformation.index);
                try {
                  await client.patch(modalInformation.endpoint, {
                    package_id: modalInformation.package_id,
                    visibility: modalInformation.visibility,
                  });
                  await fetchData();
                  setModalInformation(undefined);
                } catch (e) {
                  console.debug(e);
                } finally {
                  setIsLoadingAction(undefined);
                }
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setModalInformation(undefined)}>No</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
