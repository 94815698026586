import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { details } from '../../actions';
import { connect } from 'react-redux';

class WidgetDocumentation extends Component {
  componentDidMount = async () => {
    const { widget } = this.props;
    if (widget.doc_file && widget.doc_file?.link.startsWith('http')) {
      await this.mdFileHandler(widget.doc_file?.link);
    }
  };

  mdFileHandler = async (link) => {
    const res = await fetch(link);
    const text = await res.text();
    this.props.setWidgetDoc(text);
  };

  render() {
    const { widget } = this.props;
    if (!widget.doc_file) {
      return (
        <div>
          <ol></ol>
          <div>This plugin does not contain any documentation</div>
        </div>
      );
    }
    if (widget.doc_file && !widget.doc_file.content) {
      return (
        <div>
          <ol></ol>
          <div>Loading...</div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <ol></ol>
          <ReactMarkdown>{widget.doc_file.content}</ReactMarkdown>
        </div>
      </div>
    );
  }
}

WidgetDocumentation.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapActionsToProps = {
  setWidgetDoc: details.setWidgetDoc,
};

export default connect(mapStateToProps, mapActionsToProps)(WidgetDocumentation);
