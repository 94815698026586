import React, { Component } from 'react';
import { myPlugins } from '../../actions';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import cube from '../../assets/images/cube.png';
import ProEDMS from '../../assets/images/ProEDMS.png';
import commercialIcon from '../../assets/images/commercialIcon.png';
import properties from '../../config/properties';

class ArtifactCardViewItem extends Component {
  state = {
    popup: false,
    openMenu: false,
    anchorEl: null,
  };

  handleClick = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };

  render() {
    const { artifact } = this.props;
    const imgSrc = artifact.userId
      ? `${properties.API_HOST}packages/${artifact.imageUrl}`
      : artifact.name === 'core-plugins' ||
          artifact.name === 'transform-plugins'
        ? ProEDMS
        : artifact.imageUrl;
    return (
      <div className="col-lg-3">
        <div className="shadow">
          <div className="card">
            {artifact.visibility === 'pending' &&
              this.props.activeTab === 'myPlugins' && (
                <div className="banners-shifted">
                  <div className="pending-banner">
                    <p
                      style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                      }}
                    >
                      PENDING
                    </p>
                  </div>
                </div>
              )}
            <div className="card-header no-border">
              {this.props.activeTab === 'myPlugins' &&
                !artifact.isPurchased && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '3px',
                      left: '0px',
                      width: '0px',
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        this.setState({
                          anchorEl: event.currentTarget,
                        });
                        this.handleClick();
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={this.state.anchorEl}
                      id="long-menu"
                      open={this.state.openMenu}
                      onClose={() => {
                        this.handleClick();
                      }}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5,
                          width: '19ch',
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          this.props.EditPluginPopupHandler(artifact);
                          this.handleClick();
                        }}
                      >
                        <i className="fa fa-edit btn-edit pd-right-10"></i>
                        <Typography variant="inherit">Edit</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.props.DeletePluginPopupHandler(artifact);
                          this.handleClick();
                        }}
                      >
                        <i className="fa fa-trash btn-edit pd-right-10"></i>
                        <Typography variant="inherit">Delete</Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              <img
                className="card-img-top"
                src={imgSrc}
                alt="Not found"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ProEDMS;
                }}
              />
              {artifact.commercial === true && (
                <img
                  className="img-commercial"
                  src={commercialIcon}
                  alt="Not found"
                />
              )}
            </div>
            <div className="card-body card-body-new no-border">
              <button
                className={
                  this.props.activeTab === 'myPlugins'
                    ? 'full-link-myplugins'
                    : 'full-link'
                }
                onClick={() => this.props.goToDetails(artifact, null)}
              />
              <h5 className="card-title text-center">
                {artifact.converted_name}
              </h5>
            </div>
            <div className="card-footer no-border">
              <p className="card-text text-center">
                <label>
                  <i
                    className="fa fa-info-circle pdRight-3"
                    style={{ color: 'gray' }}
                  />
                  {artifact.widgets
                    ? artifact.widgets.length > 1
                      ? 'Bundle'
                      : artifact.widgets[0]['pluginType']
                    : ''}
                </label>
                <label className="mgLeft10">
                  <i className="fa fa-code-fork fa-lg" />
                  {artifact.version}
                </label>
                {artifact.widgets && artifact.widgets.length > 1 && (
                  <img className="pull right cubeImg" src={cube} alt="" />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  activeTab: state.activeState.activeTab,
  ...ownProps,
});

const mapActionsToProps = {
  EditPluginPopupHandler: myPlugins.EditPluginPopupHandler,
  DeletePluginPopupHandler: myPlugins.DeletePluginPopupHandler,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ArtifactCardViewItem);
