import React, { Component } from 'react';
import Properties from '../../config/properties';
import isEmpty from 'lodash/isEmpty';
import getByKey from 'lodash/get';
import commercialIcon from '../../assets/images/commercialIcon.png';
import ProEDMS from '../../assets/images/ProEDMS.png';
import openCube from '../../assets/images/openCube.png';
class WidgetCardViewItem extends Component {
  generateName = () => {
    const { artifactItemWidget } = this.props;
    const pluginTypes = [
      '-action.json',
      '-batchsource.json',
      '-batchsink.json',
      '-splittertransform.json',
      '-streamingsource.json',
      '-transform.json',
      '-postaction.json',
      '-sparksink.json',
      '-sparkcompute.json',
      '-sparkprogram.json',
      '-batchaggregator.json',
      '-batchjoiner.json',
      '-alertpublisher.json',
      '-windower.json',
    ];
    let name = artifactItemWidget.name;
    pluginTypes.forEach((element) => {
      if (artifactItemWidget.name.includes(element)) {
        name = artifactItemWidget.name.replace(element, '');
      }
    });
    return name;
  };

  render() {
    const { artifact, artifactItemWidget } = this.props;
    let imgSrc = artifactItemWidget.icon_link
      ? artifactItemWidget.icon_link
      : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/icon.png`;
    if (
      artifactItemWidget.package_name === 'core-plugins' ||
      artifactItemWidget.package_name === 'transform-plugins'
    ) {
      imgSrc =
        artifactItemWidget.icon_link !== ''
          ? artifactItemWidget.icon_link
          : ProEDMS;
      // artifactItemWidget['icon'] && artifactItemWidget['icon']
      //   ? artifactItemWidget['icon']['arguments']['data']
      //   : assets.images.ProEDMS;
    }
    const name =
      artifactItemWidget['data'] && artifactItemWidget['data']['display-name']
        ? artifactItemWidget['data']['display-name']
        : this.generateName();
    return (
      <div className="col-lg-3">
        <div className="shadow">
          <div className="card">
            <div className="card-header no-border">
              <img
                className="card-img-top"
                src={imgSrc}
                alt="Not found"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ProEDMS;
                }}
              />
              {artifact.commercial === true && (
                <img
                  className="img-commercial"
                  src={commercialIcon}
                  alt="Not found"
                />
              )}
            </div>
            <div className="card-body card-body-new no-border">
              <button
                className="btn-no-bg full-link"
                onClick={() =>
                  this.props.goToDetails(artifact, artifactItemWidget)
                }
              ></button>
              <h5 className="card-title text-center">
                {!isEmpty(name)
                  ? name
                  : getByKey(
                      artifactItemWidget.data,
                      'configuration-groups.0.label',
                      'Missing name'
                    )}
              </h5>
            </div>
            <div className="card-footer no-border">
              <p className="card-text text-center">
                <label>
                  <i
                    className="fa fa-info-circle"
                    style={{ color: 'gray', marginRight: '3px' }}
                  ></i>
                  {artifactItemWidget.pluginType}
                </label>
                <label className="mgLeft10">
                  <i
                    className="fa fa-code-fork fa-lg"
                    style={{ color: 'gray', marginRight: '3px' }}
                  ></i>
                  {artifact.version}
                </label>
                <img
                  className="pull right"
                  style={{ margin: '0px 0px 10px 2px' }}
                  src={openCube}
                  alt=""
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WidgetCardViewItem;
