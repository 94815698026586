import axios from 'axios';
import properties from './properties';
import constants from './constants';

export const client = axios.create({
  baseURL: properties.API_HOST,
});

client.interceptors.request.use(
  (config) => {
    if (config.url.includes('refresh')) {
      const refreshToken = localStorage.getItem(constants.REFRESH_TOKEN_KEY);
      if (refreshToken) {
        config.headers['Authorization'] = `Bearer ${refreshToken}`;
      }
      return config;
    }
    if (!config.url.includes('login')) {
      const accessToken = localStorage.getItem(constants.ACCESS_TOKEN_KEY);
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

client.interceptors.response.use(
  (res) => {
    if (res.config.url?.includes('login')) {
      localStorage.setItem(
        constants.REFRESH_TOKEN_KEY,
        res.data[constants.RES_REFRESH_TOKEN]
      );
    }
    if (res.data[constants.RES_ACCESS_TOKEN]) {
      localStorage.setItem(
        constants.ACCESS_TOKEN_KEY,
        res.data[constants.RES_ACCESS_TOKEN]
      );
      client.defaults.headers.common.Authorization = `Bearer ${res.data[constants.RES_ACCESS_TOKEN]}`;
    }
    if (res.data[constants.RES_REFRESH_TOKEN]) {
      localStorage.setItem(
        constants.REFRESH_TOKEN_KEY,
        res.data[constants.RES_REFRESH_TOKEN]
      );
    }
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes('login') &&
      !originalRequest.url.includes('refresh')
    ) {
      originalRequest._retry = true;
      try {
        await client.get('/auth/refresh/');
        return client(originalRequest);
      } catch (error) {
        console.debug(error);
      }
    }

    if (error.response.status === 403) {
      console.warn('Access forbidden. Please check permissions.');
    }

    return Promise.reject(error);
  }
);
