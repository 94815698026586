import React from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from '@mui/material';
import { Search } from '../../components';
import Skeleton from '@mui/lab/Skeleton';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { client } from '../../config/api';

export const UsersTable = ({
  users: usersRaw,
  purchases,
  isLoading,
  fetchData,
}) => {
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [keyword, setKeyword] = React.useState('');
  const [filteredData, setFilteredData] = React.useState([]);
  const [isLoadingActions, setIsLoadingAction] = React.useState(undefined);
  const [modalInformation, setModalInformation] = React.useState(undefined);
  const users = usersRaw.sort((a, b) =>
    a.data.email.localeCompare(b.data.email)
  );

  React.useEffect(() => {
    setFilteredData([...users].slice(page * perPage, (page + 1) * perPage));
  }, [users, page, perPage]);

  React.useEffect(() => {
    if (keyword !== '') {
      setFilteredData(
        users.filter((user) => JSON.stringify(user).includes(keyword))
      );
      return;
    }
    setPage(0);
    setFilteredData([...users].slice(page * perPage, (page + 1) * perPage));
  }, [keyword]);

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          <div
            style={{
              width: '100%',
              display: 'flex',
              padding: '10px 0',
            }}
          >
            <div
              style={{
                width: 300,
                marginLeft: 'auto',
                marginRight: -24,
              }}
            >
              <Search handleSearch={(keyword) => setKeyword(keyword)} />
            </div>
          </div>
        </Toolbar>
        <Table
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
        >
          <TableHead style={{ fontWeight: 700, borderTop: '1px solid #ccc' }}>
            <TableRow style={{ height: 50 }}>
              <TableCell style={{ fontWeight: 700, color: '#164080' }}>
                ID
              </TableCell>
              <TableCell style={{ fontWeight: 700 }}>Email</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Plan</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Purchases</TableCell>
              <TableCell style={{ fontWeight: 700 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" height={20} width={150} />
                  </TableCell>
                </TableRow>
              ))}
            {!isLoading &&
              filteredData.map((user, index) => (
                <TableRow key={user.id} style={{ height: 60 }}>
                  <TableCell style={{ color: '#164080' }}>{user.id}</TableCell>
                  <TableCell>{user.data.email}</TableCell>
                  <TableCell style={{ textTransform: 'uppercase' }}>
                    {user.data.plan_name}
                  </TableCell>
                  <TableCell>
                    {
                      purchases.filter(
                        (purchase) => purchase.data.userId === user.id
                      ).length
                    }
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        gap: 10,
                        justifyContent: 'center',
                      }}
                    >
                      {user.data?.plan_name === 'enterprise' &&
                        !user.data?.current_subscription && (
                          <Button
                            color={'secondary'}
                            onClick={() => {
                              setModalInformation({
                                user_id: user.id,
                                endpoint: '/payment/admin/revoke/enterprise/',
                                title: 'Revoke Enterprise',
                                index: index,
                              });
                            }}
                          >
                            {isLoadingActions === index ? (
                              <CircularProgress />
                            ) : (
                              'Revoke Enterprise'
                            )}
                          </Button>
                        )}
                      {user.data.plan_name !== 'enterprise' && (
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setModalInformation({
                              user_id: user.id,
                              endpoint: '/payment/admin/enterprise/',
                              title: 'Activate Enterprise',
                              index: index,
                            });
                          }}
                        >
                          {isLoadingActions === index ? (
                            <CircularProgress />
                          ) : (
                            'Activate Enterprise'
                          )}
                        </Button>
                      )}
                      {!user.data.verified_email && (
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setModalInformation({
                              user_id: user.id,
                              endpoint: '/auth/manual/email/verification/',
                              title: 'Verify User',
                              index: index,
                            });
                          }}
                        >
                          {isLoadingActions === index ? (
                            <CircularProgress />
                          ) : (
                            'Verify User'
                          )}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, value) => setPage(value)}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      {modalInformation && (
        <Modal isOpen={!!modalInformation}>
          <ModalHeader>{modalInformation.title}</ModalHeader>
          <ModalBody>
            Are you sure you want to {modalInformation.title}?
          </ModalBody>
          <ModalFooter>
            <Button
              color={'primary'}
              onClick={async () => {
                setIsLoadingAction(modalInformation.index);
                try {
                  await client.post(modalInformation.endpoint, {
                    user_id: modalInformation.user_id,
                  });
                  await fetchData();
                  setModalInformation(undefined);
                } catch (e) {
                  console.debug(e);
                } finally {
                  setIsLoadingAction(undefined);
                }
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setModalInformation(undefined)}>No</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
