import React from 'react';
import { client } from '../../config/api';
import { Paper, Tab, Tabs } from '@mui/material';
import { UsersTable } from './UsersTable';
import { PluginsTable } from './PluginsTable';

const AdminDashboard = () => {
  const [users, setUsers] = React.useState([]);
  const [purchases, setPurchases] = React.useState([]);
  const [plugins, setPlugins] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const fetchData = async () => {
    try {
      const usersResponse = await client.get('/users/all');
      const purchasesResponse = await client.get('/purchases/all');
      const pluginsResponse = await client.get('/packages_temp/unverified');
      setPurchases(purchasesResponse.data);
      setUsers(usersResponse.data);
      setPlugins(pluginsResponse.data.packages);
    } catch (e) {
      console.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container py-4 row mx-auto">
      <div className="col-xl-12 col-lg-12">
        <div className="card-stats mb-4 mb-xl-0 pd-10">
          <div className="card-body bg-white rounder-borders card-body-shadow">
            <div
              className="col-lg-12 custom-tabs"
              style={{ background: '#f8f9fe' }}
            >
              <Paper
                className="paper-nav-tabs col-lg-4"
                style={{ background: '#f8f9fe', border: 'none' }}
              >
                <Tabs
                  variant="fullWidth"
                  value={selectedTabIndex}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(_, value) => setSelectedTabIndex(value)}
                  aria-label="tabs example"
                >
                  <Tab label="Users" value={0} />
                  <Tab label="Plugins" value={1} />
                </Tabs>
              </Paper>
            </div>
            {selectedTabIndex === 0 && (
              <UsersTable
                users={users}
                purchases={purchases}
                isLoading={isLoading}
                fetchData={fetchData}
              />
            )}
            {selectedTabIndex === 1 && (
              <PluginsTable
                users={users}
                plugins={plugins}
                isLoading={isLoading}
                fetchData={fetchData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
